<template>
    <div class="sld_chat_left">
        <div class="top">
            <div class="info">
                <img :src="storeInfo.data.storeLogoUrl" alt="">
                <div class="text">
                    <p>
                        <span>{{storeInfo.data.vendorName}}</span>
                        <img src="@/assets/service/service_logo.png" alt="">
                        <!-- <i class="iconfont"></i> -->
                    </p>
                    <div class="online" ><div :class="{'green_dot':onLine,'red_dot':!onLine}"></div>({{L[onLine?'在线中':'连接中...']}}) </div>
                </div>
            </div>
        </div>
        <div class="list_wrap">
            <!--            <div class="tab">-->
            <!--                <span @click="changeTab(1)" :class="{tabChosen:1==tabIndex}"><i>进行中(1111)</i></span>-->
            <!--                <span @click="changeTab(2)" :class="{tabChosen:2==tabIndex}"><i>进行中(1111)</i></span>-->
            <!--            </div>-->
            <div class="list_con" :style="'height:'+(clientHeight-152)+'px'">
                <el-scrollbar ref="leftScroll">
                    <div  v-if="tabIndex==1&&chatList.list.length" v-infinite-scroll="load" ref="leftScrollContent">
                        <div class="list_item" :data-memberid="item.memberId"
                            :style="{'background-color':item.memberId == curMemberId?'#F5F5F5':'#fff'}"
                            v-for="(item,index) in chatList.list" :key="index"
                            @click="switchMember(item.memberId,item.memberName)">
                            <img :src="item.memberAvatar">
                            <div class="list_text">
                              <p class="mark">
                                <img @click="operate('collect', item.memberId, item.isTag, $event)" v-if="item.isTag != 2" src="@/assets/collect_empty.png" style="width: 19px; height: 19px; margin-right: 5px">
                                <img @click="operate('collect', item.memberId, item.isTag, $event)" v-if="item.isTag == 2" src="@/assets/collect_selected.png" style="width: 19px; height: 19px; margin-right: 5px">
                                <img @click="operate('member_top', item.memberId, item.isTop, $event)" v-if="item.isTop != 2" src="@/assets/member_top_empty.png" style="width: 19px; height: 19px">
                                <img @click="operate('member_top', item.memberId, item.isTop, $event)" v-if="item.isTop == 2" src="@/assets/member_top_selected.png" style="width: 19px; height: 19px">
                              </p>
                                <p class="list_item_title">{{item.memberName}}</p>
                                <p style="margin-top: 10px"><text>手机号: </text>{{item.memberMobile ? item.memberMobile : '--'}}</p>
                                <p class="msg_new">{{item.showContent}}</p>
                            </div>
                            <div class="delete_icon">
                                <i v-if="item.receiveMsgNumber"
                                    class="msg_icon">{{item.receiveMsgNumber>99?'99+':item.receiveMsgNumber}}</i>
                                <div class="del_icon">
                                    <el-popconfirm :title="L['确定结束与该会员的聊天吗？']" @confirm="closeChatMember(item.memberId)"
                                        :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                                        <template #reference>
                                            <img src="@/assets/service/delete.png" alt="">
                                        </template>
                                    </el-popconfirm>
                                </div>
                            </div>
                        </div>
                        <loadingState v-if="loadState == 'first_loading'||chatList.list.length > 0"
                            :state='loadState' />
                    </div>
                    <div class="empty_data_left" v-if="!chatList.list.length>0">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>{{L['暂无数据～']}}</p>
                    </div>
                </el-scrollbar>
                <!-- <div v-if="tabIndex==2">
                    <div class="list_item" v-for="(item,index) in [1,2,3,4,5]" :key="index" id="queuing">
                        <img src="@/assets/service/avatar.png" alt="">
                        <div class="list_text">
                            <p class="list_item_title">aawdawd{{item}}</p>
                            <p class="msg_new">awdawddadawdawdawdawdawdawdawdawdawd</p>
                        </div>
                        <div class="delete_icon">
                            <img src="@/assets/service/delete.png" alt="">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, reactive, onMounted, nextTick } from 'vue';
    import { useStore } from "vuex";
    import loadingState from '@/components/loadingState'
    export default {
        name: 'chatLeftList',
        components: {
            loadingState
        },
        props: ['connectBaseData'],
        beforeCreate() {
            this.sockets.subscribe('contact_change', (e) => {
                if (this.chatList.list.length == 0) {
                    console.log('这个是0',e);
                    this.chatList.list.push(e);
                    this.$socket.emit("vendor_change_room", { memberId: e.memberId, ...this.connectData }, () => {
                        this.emitParentData({ memberId: e.memberId, memberName: e.memberName });
                    });
                } else {

                    let cur_index = this.chatList.list.findIndex(item => item.memberId == e.memberId);
                    //判断当前聊天联系人列表中是否有该会员
                    if (cur_index == -1) {
                        // this.chatList.list.unshift(e);
                        //若没有
                        let targetIndex=this.chatList.list.findIndex(item => item.isTop == 1);
                        console.log('这里',targetIndex);
                        if(this.hasMore){
                            console.log('可下滑');
                            //若当前全为置顶则不进行任何操作，若不全为置顶则进行插入
                            if(targetIndex!=-1){
                                this.chatList.list.splice(targetIndex,0,e)
                            }
                        }else{
                            console.log('不可下滑');
                            //划不动了底部则将联系人放入对应位置---置顶下第一个位置
                            if(targetIndex!=-1){
                                this.chatList.list.splice(targetIndex,0,e)
                            }else{
                                this.chatList.list.splice(this.chatList.list.length,0,e)
                            }

                        }
                    } else {
                        //若有
                        this.chatList.list[cur_index] = e;
                    }

                }
                this.formatMsgContent();
                if (e.userType === 1 || e.userType === 3) {
                  this.sendNotification(e);
                  if (localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true') {
                    this.play();
                  }
                }
            });
            this.sockets.subscribe('unread_num_change', (e) => {
                let tmp_data = this.chatList.list.filter(item => item.memberId == e.memberId)
                if (tmp_data.length == 1) {
                    tmp_data[0].receiveMsgNumber = e.unreadNum;
                }
            });
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            const connectData = reactive(props.connectBaseData);
            const store = useStore();
            const tabIndex = ref(1);
            const storeInfo = reactive({ data: store.state.storeInfo });
            const sellerMemberId = ref( localStorage.sellerMemberId ) // 客服主动与会员对话的会员id()
            const pageSize = 20;
            const current = ref(1);
            const curMemberId = ref(0);//当前聊天会员的id
            const minMsgId = ref('');//当前消息的最小id
            const minMsgIdIsTop = ref('');//当前消息的最小id对应的isTop值
            const minMsgIdTopTime = ref('');//当前消息的最小id对应的置顶时间
            const chatList = reactive({ list: [] });//最近联系人列表
            const loadState = ref('')
            const changeTab = (index) => {
                tabIndex.value = index
            }
            const isScroll = ref(false)
            const hasMore = ref(true)
            const clientHeight = ref(0)
            const L = proxy.$getCurLanguage()
            const onLine = ref(false)

          // 置顶,收藏操作
          const operate = ( type, memberId, value, e ) => {
              let params = {}
              let url = ''
              if (type == 'collect') {
              // 收藏操作
                url = 'v3/helpdesk/seller/chat/isTag'
                params.memberId = memberId
                params.isTag = value == 1 ? 2 : 1
              } else if (type == 'member_top') {
              // 置顶操作
                url = 'v3/helpdesk/seller/chat/isTop'
                params.memberId = memberId
                params.isTop = value == 1 ? 2 : 1
              }

              proxy.$post(url, params).then(res => {
                if (res.state == 200) {
                    if(type == 'collect'){
                        console.log('收藏');
                        console.log(chatList.list);
                        //请求成功后将对应的item.isTag标记，不再请求联系人列表
                        for (let index = 0; index < chatList.list.length; index++) {
                            if(memberId == chatList.list[index].memberId){
                                chatList.list[index].isTag = params.isTag
                                break;
                            }
                        }
                    }else if(type == 'member_top'){
                        // 置顶后重新拉取联系人列表,并跳转对应的member
                        console.log('置顶-无参数');
                        getPeopleList(params.memberId,params.isTop)
                    }

                }
              })
            // 取消事件冒泡
            e.stopPropagation()
          }

          // 标记或置顶后重新获取联系人列表
          const getPeopleList = (memberId,isTop) => {
            const params = {};
            params.pageSize = pageSize;
            params.current = current.value;

            proxy.$get('v3/helpdesk/seller/chat/list', params).then(res => {
              if (res.state == 200) {
                chatList.list = res.data
                minMsgIdIsTop.value = chatList.list[chatList.list.length - 1].isTop
                minMsgIdTopTime.value = chatList.list[chatList.list.length - 1].topTime
                if(minMsgIdIsTop.value==2){
                    //置顶状态下的MsgId取当前chatList中的最小值
                    minMsgId.value = Math.min.apply(Math, chatList.list.map(item => { return item.msgId }))
                    console.log('置顶状态传的msgId',minMsgId);
                }else{
                    //非置顶状态下MsgId取chatList的最后一条的msgid
                    minMsgId.value = chatList.list[chatList.list.length - 1].msgId
                }

              }
              formatMsgContent()
              nextTick(()=>{
                //置顶成功时视口滚动到对应的会员联系人，并打开联系人对话框，取消置顶则无动作
                if(isTop==2){
                    let item = scrollMoveTo(memberId);
                    item.click()
                }
                //置顶成功时重置加载更多的标识符
                hasMore.value = true
                syncLoadState(hasMore.value)
              })
            })
          }
            //同步加载更多文案
            const syncLoadState =(hasMore)=>{
                if (hasMore) {
                    loadState.value = 'allow_loading_more';
                } else {
                    loadState.value = 'no_more_data';
                }
            }
            //获取最近联系人
            const getChatList = (id) => {
                const params = {};
                params.pageSize = pageSize;
                params.current = current.value;
                if (minMsgId.value) {
                    params.msgId = minMsgId.value;
                    // params.isTop = minMsgIdIsTop.value;
                    params.topTime = minMsgIdTopTime.value;
                }
                proxy.$get('v3/helpdesk/seller/chat/list', params).then(res => {
                    if (res.state == 200) {
                        if (minMsgId.value) {
                            chatList.list = chatList.list.concat(res.data);
                        } else {
                          chatList.list = res.data
                          if (id && res.data[0].memberId == id) {
                            localStorage.removeItem('sellerMemberId')
                            // 主动发起对话
                            switchMember(id)
                            if(res.data[0].isTop == 3){
                              let detail = {}
                              let url = ''
                              // 取消置顶操作
                              url = 'v3/helpdesk/seller/chat/isTop'
                              detail.memberId = id
                              detail.isTop = 1
                              proxy.$post(url, detail).then(res => {
                                if (res.state == 200) {
                                  console.log('主动对话取消置顶')
                                }
                              })
                            }
                          }
                        }
                        formatMsgContent()
                        if (chatList.list.length > 0) {
                            // minMsgId.value = chatList.list[chatList.list.length - 1].msgId
                            minMsgIdIsTop.value = chatList.list[chatList.list.length - 1].isTop
                            minMsgIdTopTime.value = chatList.list[chatList.list.length - 1].topTime
                            if(minMsgIdIsTop.value==2){
                                //置顶状态下的MsgId取当前chatList中的最小值
                                minMsgId.value = Math.min.apply(Math, chatList.list.map(item => { return item.msgId }))
                                console.log('置顶状态传的msgId',minMsgId);
                            }else{
                                //非置顶状态下MsgId取chatList的最后一条的msgid
                                minMsgId.value = chatList.list[chatList.list.length - 1].msgId
                            }
                            if (proxy.$socket.connected) {
                                proxy.$socket.emit("vendor_change_room", { memberId: curMemberId.value, ...connectData }, () => {
                                    if (isScroll.value == false) {
                                        emit('switchMember', { memberId: curMemberId.value, memberName: chatList.list[0].memberName });
                                    }
                                });
                            }
                            // if (isScroll.value == false) {
                            //     emit('curChatMemInfo', chatList.list[0])
                            // }

                        } else {
                            curMemberId.value = 0;
                            emit('curChatMemInfo', {})
                        }
                        hasMore.value = res.data.length < 10 ? false : true;
                        syncLoadState(hasMore.value)

                    }
                })
            }
            //格式化聊天内容，方便列表展示
            const formatMsgContent = () => {
                let reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g

                let reg4 = /(<\/?div.*?>)|(<\/?br.*?>)|(<\/?span.*?>)/g;
                if (chatList.list.length > 0) {
                    chatList.list.map(item => {
                        if (typeof item.msgContent == 'string') {
                            item.msgContent = JSON.parse(item.msgContent)
                        }
                        //1.text(文本) 2.img(图片) 3.goods(商品) 4.order(订单)用户 5.常见问题 6.系统转接消息
                        if (item.msgType == 1) {

                            if (reg.test(item.msgContent.content)) {
                                item.msgContent.content = item.msgContent.content.replace(/<img[^>]*>/g, L['[表情]'])
                                item.showContent = item.msgContent.content
                            } else {
                                item.showContent = item.msgContent.content;
                            }

                            if (reg4.test(item.msgContent.content)) {
                                item.msgContent.content = item.msgContent.content.replace(reg4, '')
                                item.showContent = item.msgContent.content
                            } else {
                                item.showContent = item.msgContent.content;
                            }

                        } else if (item.msgType == 2) {
                            item.showContent = L['[图片]'];
                        } else if (item.msgType == 3) {
                            item.showContent = L['[商品]'];
                        } else if (item.msgType == 4) {
                            item.showContent = L['[订单]'];
                        } else if (item.msgType == 6) {
                            item.showContent = L['[系统消息]'];
                        }
                    })
                }
            }

            //切换会员事件
            const switchMember = (memberId, memberName) => {
                let oldMemberId = curMemberId.value;
                curMemberId.value = memberId;
                proxy.$socket.emit("vendor_change_room", { memberId, oldMemberId, ...connectData });
                emit('switchMember', { memberId, memberName });
            }

            //关闭与会员的聊天事件
            const closeChatMember = (memberId) => {
                proxy.$socket.emit("vendor_remove_contact", { memberId, ...connectData });
                chatList.list = chatList.list.filter(item => item.memberId != memberId);
                if (curMemberId.value == memberId) {
                    if (chatList.list.length > 0) {
                        //该会员与当前聊天的会员是同一个，需要先移除会员再切换
                        switchMember(chatList.list[0].memberId, chatList.list[0].memberName);
                    } else {
                        emit('curChatMemInfo', {})
                    }
                }
            }


            // 向下滚动至底部加载数据
            const load = () => {
                console.log('滚动到底部');
                if (hasMore.value) {
                    isScroll.value = true
                    console.log('拉底 无参数');
                    getChatList()
                }
            }

            //联系人列表滑动至某个会员位置
            const scrollMoveTo=(memberid)=>{
                let itemList = proxy.$refs.leftScrollContent.querySelectorAll('.list_item')
                for (let index = 0; index < itemList.length; index++) {
                    if(memberid == itemList[index].dataset['memberid']){
                        proxy.$refs.leftScroll.wrap.scrollTo(0, itemList[index].offsetTop)
                        return itemList[index]
                    }
                }
            }
            const test = ()=>{
                console.log(chatList.list);
                // scrollMoveTo('8131')
                // console.log('leftScroll',proxy.$refs.leftScroll);
                // console.log('leftScrollContent',proxy.$refs.leftScrollContent);
                // console.log('leftScrollContent',proxy.$refs.leftScrollContent.querySelectorAll('.list_item'));
                // let itemList = proxy.$refs.leftScrollContent.querySelectorAll('.list_item')
                // console.log(itemList[5].dataset['memberid']);
                // console.log(itemList[5].offsetTop);
                // proxy.$refs.leftScroll.wrap.scrollTo(0, itemList[5].offsetTop)

                // console.log('doc',document.getElementById('9981'));
                // console.log('测试个数',proxy.$refs.leftScrollContent);
                // console.log('22222',proxy.$refs.leftScrollContent);
                // console.log('22222',proxy.$refs.leftScrollContent);
                // console.log('22222',proxy.$refs.leftScrollContent);
                // proxy.$refs.leftScroll.wrap.scrollTop = 90
                // proxy.$refs.leftScroll.wrap.scrollTo(0, 90)
                // console.log(proxy.$refs);
                // console.log(proxy.$refs['8131']);
                // console.log('scrollItem',proxy.$refs.scrollItem);
                // console.log( "scrollItemArr个数",scrollItemArr.value.length);
                // console.log( "scrollItemArr",scrollItemArr.value[0].dataset);
                // console.log( "scrollItemArr",scrollItemArr.value);
                // console.log( "scrollItemArr",scrollItemArr.value[5].dataset['memberid']);
                // console.log( "scrollItemArr",scrollItemArr.value[5].offsetTop);

                // console.log('offsetTop',proxy.$refs.leftScroll.wrap.offsetTop);

                // if (proxy.$refs.orderbar) {
                //     proxy.$refs.orderbar.wrap.scrollTop = 0
                // }

            }

            // 消息提示音
            // const play = () => {
            //     let audioElement = document.createElement('audio');
            //     let voice = require('@/assets/voice/msg.mp3')
            //     audioElement.setAttribute('src', voice);
            //     audioElement.setAttribute('autoplay', 'autoplay');
            // }

            onMounted(() => {
                setTimeout(() => {
                    storeInfo.data = JSON.parse(localStorage.getItem('storeInfo'))
                }, 1000)
                clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
                getChatList(sellerMemberId.value)

              //更新在线状态
              onLine.value = proxy.$socket.connected

            })

            const emitParentData = (data) => {
                emit('switchMember', { memberId: data.memberId, memberName: data.memberName });
            }

            // 消息提示音
            const play = () => {
              let audioElement = document.createElement('audio');
              audioElement.setAttribute('src', require('../../../assets/media/msg.mp3'));
              audioElement.setAttribute('autoplay', 'autoplay');
            }

            const reconnectGetMsg=()=>{
                proxy.$socket.emit("vendor_change_room", { memberId:curMemberId.value, ...connectData });
                let curMemberName = ''
                for (let index = 0; index < chatList.list.length; index++) {
                    if(curMemberId.value==chatList.list[index].memberId){
                        curMemberName =chatList.list[index].memberName
                    }
                }
                //重连通知刷新历史聊天记录
                emit('reconnect', { memberId: curMemberId.value, curMemberName });
            }

            return {
                tabIndex,
                changeTab,
                chatList,
                storeInfo,
                formatMsgContent,
                curMemberId,
                switchMember,
                closeChatMember,
                load,
                loadState,
                emitParentData,
                getPeopleList,
                play,
                connectData,
                clientHeight,
                L,
                sellerMemberId,
                operate,
                onLine,
                reconnectGetMsg,
                hasMore,
                test,
                scrollMoveTo,
                syncLoadState
            }
        },
        methods: {
          sendNotification(e) {
            if (window.Notification && Notification.permission === 'granted') {
              const info = {
                lang: 'zh-CN', // 语言
                body: '您有一条信息', // 消息简介
                tag: e.msgId, // 相当于通知的id，保证信息的唯一性和禁止重复发送
                icon: e.memberAvatar, // 图片
              };
              const notification = new Notification(e.storeName, info);
              if (notification) {
                notification.onclick = function() {
                  // const e = evt || window.event;
                  // window.location.href = e.currentTarget.tag;
                  window.focus(); // 可直接打开通知notification相关联的tab窗口
                };
              }
            }
          },
        },

          sockets: {
            connecting() {
                console.log('正在连接')
            },
            reconnecting(){
                console.log('重连中')
            },
            reconnect_attempt(data){
                console.log('重试连接次数',data);
                this.onLine = false;
                if(data>='5'){
                    this.$router.go() //重连4次就刷新页面
                }
            },
            reconnect_failed(){
                console.log('reconnect_failed')
            },
            disconnect() {
                console.log("Socket 断开");
            },
            reconnect(){
                console.log("Socket 重连成功");
                this.$socket.emit("connect_success",this.connectData);
                //重连需要通知一下切换room,并获取一下历史聊天信息
                this.reconnectGetMsg()
            },
            connect_failed() {
                console.log('连接失败')
            },
            connect() {
                this.onLine = true;
                console.log('连接成功')
            }
        },
    }
</script>

<style lang="scss" scoped>
    .green_dot {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #2bff00;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
        margin-right: 5px;
	}
    .red_dot{
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #ff0000;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
        margin-right: 5px;
    }
    .sld_chat_left {
        width: 300px;
        /* border: 1px solid #9999; */
        flex-shrink: 0;

        .top {
            .info {
                display: flex;
                margin-top: 11px;

                &>img {
                    width: 44px;
                    height: 44px;
                    margin-left: 20px;
                }

                .text {
                    margin-left: 12px;
                    .online{
                        display: flex;
                        align-items: center;
                        color: #51A4FD;
                    }

                    p {
                        height: 23px;
                        display: flex;
                        align-items: center;

                        span {
                            display: inline-block;
                            height: 15px;
                            line-height: 15px;
                            margin-right: 10px;
                        }

                        &:nth-child(2) {

                            color: #51A4FD;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .list_wrap {
            margin-top: 10px;
            width: 100%;

            .tab {
                height: 46px;

                span {
                    display: inline-block;
                    text-align: center;
                    height: 52px;
                    padding: 17px 35px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    border-bottom: 1px solid #f7f7f7;
                    cursor: pointer;

                    i {
                        font-style: normal;
                    }
                }

                .tabChosen {
                    border-bottom: 2px solid #0871FF;

                    i {
                        width: 95px;
                        height: 17px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #0B72F2;
                    }
                }
            }

            .list_con {
                margin-top: 10px;

                .list_item {
                    padding: 15px 17px;
                    width: 97%;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #f7f7f7;
                    position: relative;

                    &>img {
                        width: 40px;
                        height: 40px;
                    }


                    &:hover {
                        background-color: #F5F5F5;

                        .delete_icon {
                            .del_icon {

                                display: block;
                            }

                        }
                    }

                    .list_text {
                        margin-left: 10px;
                        position: relative;
                        .mark {
                          position: absolute;
                          cursor: pointer;
                          right: 0;
                          top: 0;
                        }
                        .list_item_title {
                            height: 15px;
                            line-height: 15px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }

                        .msg_new {
                            width: 175px;
                            margin-top: 9px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .delete_icon {
                        position: absolute;
                        right: 17px;
                        display: flex;

                        i {
                            font-style: normal;
                            margin-right: 6px;
                            display: inline-block;
                            padding: 2px 5px;
                            background-color: #e2231a;
                            color: #fff;
                            font-size: 12px;
                            border-radius: 9px;
                            transform: scale(0.9)
                        }

                        .del_icon {
                            display: none;
                            cursor: pointer;
                        }

                    }
                }
            }
        }
    }

    .empty_data_left {
        height: 300px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 80px;
        }

        p {
            margin-top: 15px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #D5D5D5;
        }
    }
</style>
