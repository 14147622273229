<template>
  <div class="sld_chat_right">
    <el-dialog title="商品参数" class="dialog_box" v-model="detail" width="570px" :close-on-click-modal="false"
               @close="close_dialog">

      <div style="display: flex;">
        <div class="demo-image__preview">
          <el-image
              style="width: 100px; height: 100px; margin:0 20px 0 15px"
              :src="detailsForm.data.mainImg"
              :preview-src-list="srcList.data"
              :initial-index="1"
          >
          </el-image>
        </div>
        <div style="font-size: 15px">
          <el-descriptions style="display: block; font-weight: bold;margin-top: 25px">
            <el-descriptions-item>{{ detailsForm.data.goodsName }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions style="display: block;margin-top: 5px;">
            <label class="labels" style="width:50px;">货号：</label>
            <el-descriptions-item>{{ detailsForm.data.productCode }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <el-descriptions style="display: block;margin-top: 5px;font-size: 15px;">
        <label class="labels">商品单价：</label>
        <el-descriptions-item>￥ {{ detailsForm.data.productPrice }}</el-descriptions-item>
      </el-descriptions>
      <div style="font-size: 15px;display: flex;flex-wrap: wrap">
        <el-descriptions style="width: 310px">
          <label class="labels">商品库存：</label>
          <el-descriptions-item :class="detailsForm.data.productStock != 0 ? ' ' : 'red' ">
            {{ detailsForm.data.productStock != 0 ? detailsForm.data.productStock : '库存不足' }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions style="width: 600px">
          <label class="labels">批准文号：</label>
          <el-descriptions-item>{{ detailsForm.data.approvalNumber }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <el-descriptions style="width: 280px;font-size: 15px;display: block">
        <label class="labels">实际销量：</label>
        <el-descriptions-item>{{ detailsForm.data.actualSales }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions style="width: 410px;font-size: 15px">
        <label class="labels" style="width: 85px">条形码：</label>
        <el-descriptions-item>{{ detailsForm.data.barcode }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>


    <div class="right_tab">
      <div :class="{tab1:true,on:1==tabIndex}" @click="changeTab(1)">{{ L['用户订单'] }}</div>
      <div :class="{tab2:true,on:2==tabIndex}" @click="changeTab(2)">{{ L['用户足迹'] }}</div>
      <div :class="{tab3:true,on:3==tabIndex}" @click="changeTab(3)">{{ L['商城推荐'] }}</div>
    </div>
    <!--用户足迹显示渠道搜索-->
    <div v-if="tabIndex==2">
      <el-select class="right_top" style="" v-model="selectFootChannel" placeholder="Select">
        <el-option
            v-for="item in channelMap.list"
            :key="item.channelCode"
            :label="item.channelName"
            :value="item.channelCode"
        />
      </el-select>
    </div>
    <!--商城推荐显示渠道搜索-->
    <div v-if="tabIndex==3">
      <el-select class="right_top" style="" v-model="selectRecomChannel" placeholder="Select">
        <el-option
            v-for="item in channelMap.list"
            :key="item.channelCode"
            :label="item.channelName"
            :value="item.channelCode"
        />
      </el-select>
    </div>
    <div class="right_list_con" v-if="1==tabIndex" :style="'height:'+(clientHeight-142)+'px'">
      <el-scrollbar ref="orderbar">
        <el-form :model="searchForm.data" :inline="true" class="demo-form-inline" @submit.prevent>
          <el-form-item>
            <el-input @keydown="orderSearch" v-model="searchForm.data.msg" clearable class="search_input"
                      style="margin: 10px 0 0 10px;" placeholder="输入订单号或商品名,回车进行搜索"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="margin: 10px 0 0 10px;" @click="searchConfirm(1)">搜索</el-button>
          </el-form-item>
        </el-form>
        <!-- 订单列表start -->
        <div class="orderOwn" v-infinite-scroll="load">
          <div class="order_item"
               v-for="({orderSn,createTime,orderStateValue,orderProductList,storeRemark,orderChannelName},orderIndex) in orderList.list"
               :key="orderIndex">
            <div class="order_title">
              <div class="order_title_info">
                <p>{{ L['订单号'] }}：{{ orderSn }} <a :href='getGoodsHref(orderSn)' target="_blank"
                                                   style="margin-left: 30px;color: #666666;">{{ L['查看详情'] }} ></a></p>
                <div class="order_create_time">
                  <div>{{ createTime }}</div>
                  <div class="order_channel">{{ orderChannelName?orderChannelName:'未知渠道'}}</div>
                </div>
              </div>
              <div class="order_state">{{ orderStateValue }}</div>
            </div>
            <div v-if="storeRemark" class="store_remark order_type" @click="storeRemarkEdit(storeRemark,orderSn,'edit')">
              <p class="store_remark_left" >备注 ：</p>
              <el-scrollbar class="store_remark_right">
                <div class="content">
                  {{ storeRemark }}
                </div>
              </el-scrollbar>
            </div>
            <div v-else class="store_remark_add" @click="storeRemarkEdit('',orderSn,'add')">
              <div>添加备注</div>
              <img class="edit_store_remark_img" src="@/assets/order/edit_store_remark.png"/>
            </div>
            <div class="goods_model order_type" v-for="(goodsItem,goodsIndex) in orderProductList"
                 :key="goodsIndex">
              <div class="goods_m_img">
                <img :src="goodsItem.productImage" alt="">
              </div>
              <div class="goods_info">
                <div class="goods_info_title">
                  {{ goodsItem.goodsName }}
                </div>
                <div class="goods_info_bottom">
                  <span>￥{{ goodsItem.productShowPrice }}</span>
                  <span
                      @click="sendLink({orderSn,createTime,orderStateValue,goodsItem},'order',$event)">{{ L['发送链接'] }}
                                        ></span>
                </div>
              </div>
            </div>
          </div>
          <loadingState v-if="loadState.orderState  == 'first_loading'||orderList.list.length > 0"
                        :state='loadState.orderState'/>
          <div class="empty_data" v-if="!orderList.list.length">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无订单～'] }}</p>
          </div>
        </div>
        <!-- 订单列表start -->
      </el-scrollbar>
    </div>

    <div class="right_list_con" v-if="2==tabIndex" :style="'height:'+(clientHeight-142)+'px'">
      <el-form :model="searchForm.data" :inline="true" class="demo-form-inline" @submit.prevent>
        <el-form-item>
          <el-input @keydown="footSearch" v-model="searchForm.data.msg" clearable class="search_input"
                    style="margin: 10px 0 0 10px;" placeholder="输入商品名,回车进行搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin: 10px 0 0 10px;" @click="searchConfirm(2)">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-scrollbar ref="footbar">
        <!-- 我的足迹start -->
        <div class="foot_print" v-infinite-scroll="load">
          <div class="goods_model" v-for="(footItem,footIndex) in footPrintList.list" :key="footIndex">
            <div class="goods_m_img">
              <img :src="footItem.goodsImage" alt="">
            </div>
            <div class="goods_info">
              <div class="goods_info_title">
                {{ footItem.goodsName }}
              </div>
              <div class="goods_info_bottom">
                <span>￥{{ footItem.productPrice }}</span>
                <span @click="toDetails(footItem.productId)" style="cursor:pointer; color: #e2231a; margin-top: 2px">商品参数 &nbsp;&nbsp;&nbsp;|</span>
                <span style="color: #e2231a; margin-top: 2px">
                                <a :href="getGoodsDetails(footItem.productId)" style="color: #e2231a" target="_blank">
                                  商品详情
                                </a>&nbsp;&nbsp;&nbsp;|</span>
                <span @click="sendLink(footItem,'foot',$event)">{{ L['发送链接'] }}</span>
              </div>
            </div>
          </div>
          <loadingState v-if="loadState.footState == 'first_loading'||footPrintList.list.length > 0"
                        :state="loadState.footState"/>
          <div class="empty_data" v-if="!footPrintList.list.length">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无足迹～'] }}</p>
          </div>
        </div>
        <!-- 我的足迹end -->
      </el-scrollbar>
    </div>


    <div class="right_list_con" v-if="3==tabIndex" :style="'height:'+(clientHeight-142)+'px'">
      <el-form :model="searchForm.data" :inline="true" class="demo-form-inline" @submit.prevent>
        <el-form-item>
          <el-input @keydown="RecomSearch" v-model="searchForm.data.msg" clearable class="search_input"
                    style="margin: 10px 0 0 10px;" placeholder="输入商品名,回车进行搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin: 10px 0 0 10px;" @click="searchConfirm(3)">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-scrollbar ref="recombar">
        <!-- 商城推荐start -->
        <div class="store_recom" v-infinite-scroll="load">
          <div class="goods_model" v-for="(recomItem,recomIndex) in recomList.list" :key="recomIndex">
            <div class="goods_m_img">
              <img :src="recomItem.mainImage" alt="">
            </div>
            <div class="goods_info">
              <div class="goods_info_title">
                {{ recomItem.goodsName }}
              </div>
              <div class="goods_info_bottom">
                <span>￥{{ recomItem.goodsPrice }}</span>
                <span @click="toDetails(recomItem.productId)" style="cursor:pointer; color: #e2231a; margin-top: 2px">商品参数 &nbsp;&nbsp;&nbsp;|</span>
                <span style="color: #e2231a; margin-top: 2px">
                                <a :href="getGoodsDetails(recomItem.productId)" style="color: #e2231a" target="_blank">
                                  商品详情
                                </a>&nbsp;&nbsp;&nbsp;|</span>
                <span @click="sendLink(recomItem,'recom',$event)">{{ L['发送链接'] }}</span>
              </div>
            </div>
          </div>
          <loadingState v-if="loadState.recomState == 'first_loading'||recomList.list.length > 0"
                        :state="loadState.recomState"/>
          <div class="empty_data" v-if="!recomList.list.length">
            <img src="@/assets/goods/empty_data.png" alt="">
            <p>{{ L['暂无商品～'] }}</p>
          </div>
        </div>
        <!-- 商城推荐end -->
      </el-scrollbar>
    </div>


    <!-- 添加商铺备注对话框 -->
    <el-dialog class="dialog" :model-value="showDialog" :close-on-click-modal="false" width="600px"
               :close-on-press-escape="false" @close="dialogClosed">
      <template #title>
        <div style="color: white">
          商家订单备注
        </div>
      </template>
      <div class="dialog_content">
        <div class="dialog_content_title">订单备注:</div>
        <el-input
            v-model="textarea"
            maxlength="200"
            placeholder="备注信息"
            show-word-limit
            rows="4"
            type="textarea"
            resize="none"
        />
      </div>
      <div class="dialog_content_tip">
        最多输入200字
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showDialog=false">取消</el-button>
          <el-button style="background: #1879bd;color: white" @click="submitRemark">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {ref, onMounted, reactive, getCurrentInstance, watch} from 'vue'
import loadingState from '@/components/loadingState'
import {storeUrl} from "@/utils/config";
import {pcUrl} from "../../../utils/config";
import {ElMessage} from "element-plus";

export default {
  name: 'chatRightList',
  components: {
    loadingState
  },
  setup(props, {emit}) {
    const channelMap = reactive({
      list: [
        {channelCode: '--', channelName: '--'}
      ]
    });
    const selectFootChannel = ref('--');
    const selectRecomChannel = ref('--');
    const showDialog = ref(false)//是否显示备注对话框
    const tabIndex = ref(1)
    const textarea = ref('')//备注对话框信息内容
    const curRemarkOrder = ref('')//当前选中要提交备注信息的订单号
    const changeTab = (index) => {
      searchForm.data.msg = ''
      tabIndex.value = index
    }
    const {proxy} = getCurrentInstance()
    const orderList = reactive({list: []})
    const footPrintList = reactive({list: []})
    const recomList = reactive({list: []})
    // 搜索form
    const searchForm = reactive({data: {}});
    const current = reactive({
      order: 1,
      foot: 1,
      recom: 1
    })
    const hasMore = reactive({
      orderMore: true,
      footMore: true,
      recomMore: true
    })
    const loadState = reactive({
      orderState: '',
      footState: '',
      recomState: ''
    })
    const clientHeight = ref(0)
    const L = proxy.$getCurLanguage()
    const detail = ref(false)
    const srcList = reactive({data: []});
    const detailsForm = reactive({data: {}});


    //点击备注信息
    const storeRemarkEdit = (val, orderSn, type) => {
      curRemarkOrder.value = orderSn
      if (type == 'add') {
        textarea.value = ''
      }
      if (type == 'edit') {
        textarea.value = val
      }
      showDialog.value = true

    }
    //提交备注信息
    const submitRemark = () => {
      textarea.value
      let params = {
        remark: textarea.value,
        orderSn: curRemarkOrder.value,
      }
      console.log('params', params)
      proxy.$post('v3/business/seller/orderInfo/remark', params).then(res => {
        ElMessage.success(res.msg)
        dialogClosed()
        //备注请求成功后更新当前订单备注数据
        orderList.list.filter(item=>{
          return  item.orderSn == params.orderSn
        }).forEach(item=>{
          item.storeRemark = params.remark
        })
      })

    }

    //商铺备注对话框关闭
    const dialogClosed = () => {
      showDialog.value = false
      textarea.value = ''
      curRemarkOrder.value = ''
    }

    const getGoodsHref = (val) => {
      return storeUrl + 'order/order_lists_to_detail?orderSn=' + val
    }

    const toDetails = (id) => {
      let params = {
        productId: id
      }
      proxy.$get('v3/goods/seller/goods/helpDeskGetGoods', params).then(res => {
        if (res.state == 200) {
          detailsForm.data = res.data
          srcList.data[0] = res.data.mainImg
        }
      })
      detail.value = true
    }
    const getGoodsDetails = (val) => {
      // return pcUrl + 'goods/detail?productId='+ val
      return pcUrl + 'standard/product/detail?productId=' + val
    }

    const getOrderList = () => {
      proxy.$get('/v3/business/seller/orderInfo/userOrders', {
        current: current.order,
        memberId: CurmemberId.value
      }).then(res => {
        if (res.state == 200) {
          if (current.order == 1) {
            orderList.list = res.data.list
          } else {
            orderList.list = orderList.list.concat(res.data.list)
          }
          hasMore.orderMore = checkPaginationHasMore(res.data.pagination)
          if (hasMore.orderMore) {
            current.order++
            loadState.orderState = 'allow_loading_more';
          } else {
            loadState.orderState = 'no_more_data';
          }
        }
      })
    }

    const getFootList = () => {
      let params = {}
      //构造请求参数
      if (selectFootChannel.value && selectFootChannel.value != '--') {
        params = {current: current.foot, memberId: CurmemberId.value, channelCode: selectFootChannel.value}
      } else {
        params = {current: current.foot, memberId: CurmemberId.value}
      }
      proxy.$get('v3/member/seller/productLook/userFootprint', params).then(res => {
        if (res.state == 200) {
          if (current.foot == 1) {
            footPrintList.list = res.data.list
          } else {
            footPrintList.list = footPrintList.list.concat(res.data.list)
          }
          hasMore.footMore = checkPaginationHasMore(res.data.pagination)
          if (hasMore.footMore) {
            current.foot++
            loadState.footState = 'allow_loading_more';
          } else {
            loadState.footState = 'no_more_data';
          }
        }
      })
    }

    const getRecomList = () => {
      let params = {}
      //构造请求参数
      if (selectRecomChannel.value && selectRecomChannel.value != '--') {
        params = {current: current.recom, channelCode: selectRecomChannel.value}
      } else {
        params = {current: current.recom}
      }
      proxy.$get('/v3/goods/seller/goods/list', params).then(res => {
        if (res.state == 200) {
          if (current.recom == 1) {
            recomList.list = res.data.list
          } else {
            recomList.list = recomList.list.concat(res.data.list)
          }
          hasMore.recomMore = checkPaginationHasMore(res.data.pagination)
          if (hasMore.recomMore) {
            current.recom++
            loadState.recomState = 'allow_loading_more';
          } else {
            loadState.recomState = 'no_more_data';
          }

        }
      })
    }
    const getChannelList = () => {
      console.log('getChannelList')
      proxy.$get('v3/system/seller/channel/list').then(res => {
        if (res.state == 200) {
          console.log('res:', res)
          channelMap.list = res.data.list;
        }
      })
    }

    const CurmemberId = ref(0)
    const getSwitch = (memberId, channelCode) => {
      setDefaultChannelCode(channelCode)
      CurmemberId.value = memberId
      current.order = 1
      current.foot = 1
      current.recom = 1
      getFootList()
      getOrderList()
      getRecomList()
    }

    const orderSearch = (e) => {
      if (e.code == 'Enter') {
        searchConfirm(1)
      }
    }
    const footSearch = (e) => {
      if (e.code == 'Enter') {
        searchConfirm(2)
      }
    }
    const RecomSearch = (e) => {
      if (e.code == 'Enter') {
        searchConfirm(3)
      }
    }

    const setDefaultChannelCode = (channelCode) => {
      console.log('setDefaultChannelCode', channelCode)
      if (!channelCode) {
        return
      }
      selectFootChannel.value = channelCode
      selectRecomChannel.value = channelCode
    }

    const searchConfirm = (val) => {
      if (val == 2 && selectFootChannel.value == '--') {
        ElMessage.warning(L["请选择渠道"])
        return
      }
      if (val == 3 && selectRecomChannel.value == '--') {
        ElMessage.warning(L["请选择渠道"])
        return
      }

      if (val == 1) {
        let data = searchForm.data.msg.trim()
        current.order = 1
        proxy.$get('/v3/business/seller/orderInfo/userOrders', {
          current: current.order,
          memberId: CurmemberId.value,
          likeParme: data
        }).then(res => {
          if (res.state == 200) {
            if (current.order == 1) {
              orderList.list = res.data.list
            } else {
              orderList.list = orderList.list.concat(res.data.list)
            }
            hasMore.orderMore = checkPaginationHasMore(res.data.pagination)
            if (hasMore.orderMore) {
              current.order++
              loadState.orderState = 'allow_loading_more';
            } else {
              loadState.orderState = 'no_more_data';
            }
          }
        })
      } else if (val == 2) {
        let data = searchForm.data.msg.trim()
        current.foot = 1
        //构造请求参数
        let params = {}
        if (selectFootChannel.value && selectFootChannel.value != '--') {
          params = {
            current: current.foot,
            memberId: CurmemberId.value,
            goodsNameLike: data,
            channelCode: selectFootChannel.value
          }
        } else {
          params = {current: current.foot, memberId: CurmemberId.value, goodsNameLike: data}
        }
        proxy.$get('v3/member/seller/productLook/userFootprint', params).then(res => {
          if (res.state == 200) {
            if (current.foot == 1) {
              footPrintList.list = res.data.list
            } else {
              footPrintList.list = footPrintList.list.concat(res.data.list)
            }
            hasMore.footMore = checkPaginationHasMore(res.data.pagination)
            if (hasMore.footMore) {
              current.foot++
              loadState.footState = 'allow_loading_more';
            } else {
              loadState.footState = 'no_more_data';
            }
          }
        })
      } else if (val == 3) {
        let data = searchForm.data.msg.trim()
        current.recom = 1

        //构造请求参数
        let params = {}
        if (selectRecomChannel.value && selectRecomChannel.value != '--') {
          params = {current: current.recom, goodsName: data, channelCode: selectRecomChannel.value}
        } else {
          params = {current: current.recom, goodsName: data}
        }
        proxy.$get('/v3/goods/seller/goods/list', params).then(res => {
          if (res.state == 200) {
            if (current.recom == 1) {
              recomList.list = res.data.list
            } else {
              recomList.list = recomList.list.concat(res.data.list)
            }
            hasMore.recomMore = checkPaginationHasMore(res.data.pagination)
            if (hasMore.recomMore) {
              current.recom++
              loadState.recomState = 'allow_loading_more';
            } else {
              loadState.recomState = 'no_more_data';
            }

          }
        })
      }

    }

    const sendLink = (obj, type, e) => {
      emit('getObj', obj, type)
      e.stopPropagation();
    }

    const load = () => {
      if (tabIndex.value == 1 && hasMore.orderMore) {
        getOrderList()
      } else if (tabIndex.value == 2 && hasMore.footMore) {
        getFootList()
      } else if (tabIndex.value == 3 && hasMore.recomMore) {
        getRecomList()
      }

    }

    watch(() => CurmemberId.value, (nv, ov) => {
      if (nv != ov) {
        if (proxy.$refs.orderbar) {
          proxy.$refs.orderbar.wrap.scrollTop = 0
        }

        if (proxy.$refs.footbar) {
          proxy.$refs.footbar.wrap.scrollTop = 0
        }
      }
    })

    const checkPaginationHasMore = ({current, pageSize, total}) => {
      return current * pageSize < total * 1;
    }


    onMounted(() => {
      console.log('界面加载')
      clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
      getRecomList()
      getChannelList()
    })

    return {
      channelMap,
      selectFootChannel,
      selectRecomChannel,
      textarea,
      curRemarkOrder,
      submitRemark,
      dialogClosed,
      showDialog,
      storeRemarkEdit,
      getGoodsHref,
      srcList,
      detail,
      getGoodsDetails,
      toDetails,
      detailsForm,
      orderSearch,
      RecomSearch,
      footSearch,
      searchConfirm,
      changeTab,
      tabIndex,
      orderList,
      footPrintList,
      recomList,
      searchForm,
      getOrderList,
      getFootList,
      getRecomList,
      sendLink,
      load,
      loadState,
      getSwitch,
      CurmemberId,
      clientHeight,
      getChannelList,
      setDefaultChannelCode,
      L
    }

  }
}
</script>

<style lang="scss" scoped>
.sld_chat_right {
  width: 380px;
  margin-left: 14px;
  flex-shrink: 0;

  .right_top {
    height: 50px;
    margin-left: 10px;
    width: 260px;
    padding: 15px 0px;
    display: flex;
    align-items: center;
    display: block;
    width: 260px;
  }

  .right_tab {
    display: flex;
    flex: 1;
    background-color: #F8F8F8;

    div[class^="tab"] {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      padding: 17px 35px;
      cursor: pointer;
    }

    .on {
      border-top: 2px solid #0E6FD7;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold !important;
      color: #0E6FD7 !important;
    }
  }

  .right_list_con {
    .orderOwn {
      .order_item {
        padding: 20px;
        border-bottom: 1px solid #f8f8f8;

        .order_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #f8f8f8;

          .order_title_info {
            .order_create_time {
              display: flex;
              align-items: center;
              font-size: 12px;
              height: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;

              .order_channel {
                display: flex;
                align-items: center;
                max-width: 80px;
                height: 16px;
                line-height: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 11px;
                padding: 1px 5px;
                border-radius: 3px;
                text-align: center;
                color: #fff;
                background-color: #2C8FD5;
                margin-left: 20px;
              }
            }


            p:first-child {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              margin-bottom: 5px;
            }

            p:nth-child(2) {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }

          .order_state {
            /* width: 50px; */
            padding-left: 5px;
            padding-right: 5px;
            height: 20px;
            background: #EEEEEE;
            border-radius: 10px;
            bottom: 0;
            right: 10px;
            text-align: center;
            line-height: 20px;
            color: #666666 !important;
          }
        }

        .order_type {
          margin-top: 17px;
        }
      }


    }

    .foot_print {
      padding: 0px 20px;
    }

    .store_recom {
      padding: 0px 20px;
    }

    ::v-deep {
      .el-form-item {
        margin-bottom: 0 !important;
      }
    }
  }

  .store_remark {
    display: flex;
    height: 60px;

    .store_remark_left {
      display: flex;
      width: 40px;
    }

    .store_remark_right {
      height: 60px;
      display: flex;
      flex: 1;

      .content {
        padding: 0px 5px 0px 5px;
      }
    }
  }

  .store_remark_add {
    margin-top: 22px;
    display: flex;
    align-content: center;

    .edit_store_remark_img {
      width: 15px;
      height: 15px;
      margin-left: 8px;
      cursor: pointer;
    }
  }


  .goods_model {
    display: flex;
    margin-top: 22px;


    .goods_m_img {
      width: 69px;
      height: 66px;
      border-radius: 6px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      overflow: hidden;

      img {
        /* width: 69px; */
        height: 66px;
        /* border-radius: 6px; */
      }
    }


    .goods_info {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 81%;

      .goods_info_title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .goods_info_bottom {
        display: flex;
        cursor: pointer;
        justify-content: space-between;


        span:first-child {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #e2231a;
        }

        span:nth-child(4) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #e2231a;
          margin-top: 2px;
          cursor: pointer;
        }
      }
    }
  }
}

.empty_data {
  height: 300px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80px;
  }

  p {
    margin-left: 10px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #D5D5D5;
  }
}

.search_input {
  width: 260px;
}

.forms {
  flex: 1;
  margin-left: 100px;
}

.labels {
  display: inline-block;
  width: 100px;
  margin-top: 20px;
  text-align: right;
}

.red {
  color: red;
  font-weight: bold;
}

.dialog {

}

.dialog_content {
  width: 100%;
  display: flex;
  flex-direction: row;

  .dialog_content_title {
    padding-top: 5px;
    width: 80px;
  }

  .dialog_content_detail {

  }
}

.dialog_content_tip {
  margin-top: 10px;
  margin-left: 70px;
  color: #8c8c8c;
  font-size: 12px;
}

.dialog_footer {

}

::v-deep .el-dialog__header {
  background: #1879bd;
  padding-bottom: 15px;
}

</style>