<template>
  <div class="sld_chat_page">

    <chatLeftList @curChatMemInfo="curChatMemInfo" @switchMember="switchMember" ref="left" @reconnect="reconnect"
                  :connectBaseData="connectBaseData"/>

    <div class="chat_zone" :style="{'background':curChatMemberId?'#fff':'#f1f1f2'}">
      <section v-show="curChatMemberId">
        <div class="top">
          <div class="top_title">{{ curChatMemberInfo.data.memberName }}</div>
          <div class="top_channel">【{{ currentUserChannel }}】</div>
          <div class="top_chat_source_url">{{ chatSourceUrl }}</div>
        </div>
        <div class="chat_wrap">
          <!-- 聊天界面框start -->
          <div class="chat_window" :style="'height:'+(clientHeight-339)+'px'">
            <el-scrollbar ref="scroll" id="chat">
              <!-- <div v-if="msgList.list.length>0"> -->
              <el-dialog title="商品参数" class="dialog_box" v-model="detail" width="570px" :close-on-click-modal="false"
                         @close="close_dialog">

                <div style="display: flex;">
                  <div class="demo-image__preview">
                    <el-image
                        style="width: 100px; height: 100px; margin:0 20px 0 15px"
                        :src="detailsForm.data.mainImg"
                        :preview-src-list="srcList.data"
                        :initial-index="1"
                    >
                    </el-image>
                  </div>
                  <div style="font-size: 15px">
                    <el-descriptions style="display: block; font-weight: bold;margin-top: 25px">
                      <el-descriptions-item>{{ detailsForm.data.goodsName }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions style="display: block;margin-top: 5px;">
                      <label class="labels" style="width:50px;">货号：</label>
                      <el-descriptions-item>{{ detailsForm.data.productCode }}</el-descriptions-item>
                    </el-descriptions>
                  </div>
                </div>
                <el-descriptions style="display: block;margin-top: 5px;font-size: 15px;">
                  <label class="labels">商品单价：</label>
                  <el-descriptions-item>￥ {{ detailsForm.data.productPrice }}</el-descriptions-item>
                </el-descriptions>
                <div style="font-size: 15px;display: flex;flex-wrap: wrap">
                  <el-descriptions style="width: 310px">
                    <label class="labels">商品库存：</label>
                    <el-descriptions-item :class="detailsForm.data.productStock != 0 ? ' ' : 'red' ">
                      {{ detailsForm.data.productStock != 0 ? detailsForm.data.productStock : '库存不足' }}
                    </el-descriptions-item>
                  </el-descriptions>

                  <el-descriptions style="width: 600px">
                    <label class="labels">批准文号：</label>
                    <el-descriptions-item>{{ detailsForm.data.approvalNumber }}</el-descriptions-item>
                  </el-descriptions>
                </div>
                <el-descriptions style="width: 280px;font-size: 15px;display: block">
                  <label class="labels">实际销量：</label>
                  <el-descriptions-item>{{ detailsForm.data.actualSales }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions style="width: 410px;font-size: 15px">
                  <label class="labels" style="width: 85px">条形码：</label>
                  <el-descriptions-item>{{ detailsForm.data.barcode }}</el-descriptions-item>
                </el-descriptions>
              </el-dialog>
              <div v-for="(item, index) in msgList.list" :key="index">
                <div v-if="index==0||(index>0&&$isShowTime(msgList.list[index-1].addTime,item.addTime))"
                     class="time_line">
                  {{ $formatChatTime(item.addTime) }}
                </div>

                <!-- 当次回话撤回的消息 -->
                <div v-if="item.isRecall==1" class="time_line">
                  消息已撤回
                </div>
                <!-- 聊天记录中的已撤回消息已在后台过滤，不传给前端-->


                <!-- 会员发送的信息start -->
                <template v-if="item.userType == 1">

                  <!-- 文本类型start -->
                  <div class="chat_ladder user" v-if="item.msgType==1">
                    <img :src="item.memberAvatar" class="conten_left">
                    <div class="content_right">
                      <p>{{ item.memberName }}</p>
                      <span class="content_text text_type"
                            v-html="JSON.parse(item.msgContent).content"></span>
                    </div>
                  </div>
                  <!-- 文本类型end -->

                  <!-- 图片类型start -->
                  <div class="chat_ladder user" v-if="item.msgType==2">
                    <img :src="item.memberAvatar" class="conten_left">
                    <div class="content_right">
                      <p class="name">{{ item.memberName }}</p>
                      <div class="content_text image_type">
                        <el-image :src="JSON.parse(item.msgContent).pic"
                                  :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                  hide-on-click-modal="true">
                          <template #placeholder>
                            <div class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </template>
                        </el-image>
                      </div>
                    </div>
                  </div>
                  <!-- 图片类型end -->

                  <!-- 商品类型start -->
                  <div class="chat_ladder user" v-if="item.msgType==3">
                    <img :src="item.memberAvatar" class="conten_left">
                    <div class="content_right">
                      <p class="name">{{ item.memberName }}</p>
                      <div class="content_text goods_type">
                        <div class="goods_model" :key="index">
                          <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                          <div class="goods_info">
                            <div class="goods_info_title">
                              {{ JSON.parse(item.msgContent).goodsName }}
                            </div>
                            <div class="goods_info_bottom">
                              <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                              <span @click="toDetails(JSON.parse(item.msgContent).productId)" style="margin-left: 40px"
                                    class="details_btn">商品参数</span>
                              <span style="margin-left: 20px" class="details_btn">
                                                              <a :href="getGoodsDetails(JSON.parse(item.msgContent).productId)"
                                                                 style="color: red" target="_blank">
                                                              商品详情
                                                              </a>
                                                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 商品类型end -->

                  <!-- 订单类型start -->
                  <div class="chat_ladder user" v-if="item.msgType==4">
                    <img :src="item.memberAvatar" class="conten_left">
                    <a :href='getGoodsHref(JSON.parse(item.msgContent).orderSn)' target="_blank">
                      <div class="content_right">
                        <p class="name">{{ item.memberName }}</p>
                        <div class="content_text order_type">
                          <div class="order_title">
                                                    <span
                                                        class="order_sn">{{
                                                        L['订单号']
                                                      }}：<i>{{ JSON.parse(item.msgContent).orderSn }}</i></span>
                            <span
                                class="order_time">{{ JSON.parse(item.msgContent).createTime }}</span>
                          </div>
                          <div class="goods_model order_type">
                            <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                            <div class="goods_info">
                              <div class="goods_info_title">
                                {{ JSON.parse(item.msgContent).goodsName }}
                              </div>
                              <div class="goods_info_bottom goods_info_bottom_detial">
                                <span>￥{{
                                    JSON.parse(item.msgContent).productShowPrice ? JSON.parse(item.msgContent).productShowPrice : JSON.parse(item.msgContent).goodsPrice
                                  }}</span>
                                <span
                                    class="goods_info_bottom_ok">{{
                                    JSON.parse(item.msgContent).orderStateValue
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <!-- 订单类型end -->
                </template>
                <!-- 会员发送的信息end -->

                <template v-if="item.userType == 3">
                  <div class="time_line">{{ L['系统转接'] }}: {{ L['来自'] }}{{ JSON.parse(item.msgContent).content }}</div>
                </template>

                <!-- 客服发送的消息start (不显示已撤回的消息-(item.isRecall == 0 || item.isRecall == undefined))-->
                <template v-if="item.userType == 2 && (item.isRecall == 0 || item.isRecall == undefined)">
                  <!-- 文本类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType == 1 || item.msgType == 5 || item.msgType==7">
                    <div :class="{msg_read:item.msgState==1,msg_not_read:item.msgState==2}">
                      {{ item.msgState == 1 ? L['已读'] : L['未读'] }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <el-popover placement="bottom" :width="100" trigger="click" :ref="'reca-'+item.msgId">
                        <template #reference>
                                                    <span class="content_text text_type"
                                                          v-html="JSON.parse(item.msgContent).content"></span>
                        </template>
                        <div class="recall_box" @click="recall(item)">撤回消息</div>
                      </el-popover>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                  </div>
                  <!-- 文本类型end -->

                  <!-- 商品类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType==3">
                    <div :class="{msg_read:item.msgState==1,msg_not_read:item.msgState==2}">
                      {{ item.msgState == 1 ? L['已读'] : L['未读'] }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <el-popover placement="bottom" :width="100" trigger="click" :ref="'reca-'+item.msgId">
                        <template #reference>
                          <div class="content_text goods_type">
                            <div class="goods_model" :key="index">
                              <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                              <div class="goods_info">
                                <div class="goods_info_title">
                                  {{ JSON.parse(item.msgContent).goodsName }}
                                </div>
                                <div class="goods_info_bottom">
                                  <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                  <span @click="toDetails(JSON.parse(item.msgContent).productId)"
                                        style="margin-left: 40px" class="details_btn">商品参数</span>
                                  <span style="margin-left: 20px" class="details_btn">
                                                                    <a :href="getGoodsDetails(JSON.parse(item.msgContent).productId)"
                                                                       style="color: red" target="_blank">
                                                                    商品详情
                                                                    </a>
                                                                    </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <div class="recall_box" @click="recall(item)">撤回消息</div>
                      </el-popover>

                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                  </div>
                  <!-- 商品类型end -->


                  <!-- 订单类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType==4">
                    <div :class="{msg_read:item.msgState==1,msg_not_read:item.msgState==2}">
                      {{ item.msgState == 1 ? L['已读'] : L['未读'] }}
                    </div>
                    <a :href='getGoodsHref(JSON.parse(item.msgContent).orderSn)' target="_blank">
                      <div class="content_right">
                        <p class="name">{{ item.vendorName }}</p>
                        <div class="content_text order_type">
                          <div class="order_title">
                                                    <span
                                                        class="order_sn">{{
                                                        L['订单号']
                                                      }}：<i>{{ JSON.parse(item.msgContent).orderSn }}</i></span>
                            <span
                                class="order_time">{{ JSON.parse(item.msgContent).createTime }}</span>
                          </div>
                          <div class="goods_model order_type">
                            <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                            <div class="goods_info">
                              <div class="goods_info_title">
                                {{ JSON.parse(item.msgContent).goodsName }}
                              </div>
                              <div class="goods_info_bottom goods_info_bottom_detial">
                                <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                <span class="goods_info_order_recall" @click="recallorder($event,item)">撤回消息</span>
                                <span
                                    class="goods_info_bottom_ok">{{
                                    JSON.parse(item.msgContent).orderStateValue
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <!-- 订单类型end -->

                  <!-- 图片类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType==2">
                    <div :class="{msg_read:item.msgState==1,msg_not_read:item.msgState==2}">
                      {{ item.msgState == 1 ? L['已读'] : L['未读'] }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <el-popover placement="bottom" :width="100" trigger="click" :ref="'reca-'+item.msgId">
                        <template #reference>
                          <div class="content_text image_type">
                            <el-image :src="JSON.parse(item.msgContent).pic"
                                      :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                      hide-on-click-modal="true">
                              <template #placeholder>
                                <div class="image-slot">
                                  <i class="el-icon-picture-outline"></i>
                                </div>
                              </template>
                            </el-image>
                          </div>
                        </template>
                        <div class="recall_box" @click="recall(item)">撤回消息</div>
                      </el-popover>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar">
                  </div>
                  <!-- 图片类型end -->

                </template>
                <!-- 客服发送的消息end -->
              </div>
              <!-- </div> -->
              <!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
              <div></div>
            </el-scrollbar>
          </div>
          <!-- 聊天界面框end -->

          <!-- 聊天输入框start -->
          <div class="chat_input" id="chat_input">
            <div class="chat_tool">
              <!-- 表情start -->
              <el-popover placement="top-start" :width="300" trigger="click">
                <template #reference>
                  <a class="face_ex">
                    <img src="@/assets/service/face_ex.png" alt="">
                    <!-- <i class="iconfont icon-biaoqing"></i> -->
                  </a>
                </template>
                <div class="emoji_s">
                  <div v-for="(e,i) in emojis" :key="i" :title="e.title" class="emoji_item"
                       @click="transEmoji(e.src)">
                    <img :src="require('@/assets/emoji/'+(e.src))" alt="">
                  </div>
                </div>
              </el-popover>
              <!-- 表情end -->

              <!-- 图片start -->
              <label for="image">
                <img src="@/assets/service/pic.png" alt="">
              </label>
              <input type="file" id="image" @change="getImage" ref="imageFile">
              <!-- 图片end -->

              <!-- 快捷语恢复start -->
              <el-popover placement="top-start" :width="780" trigger="click" v-model:visible="popFlag">
                <template #reference>
                  <img src="@/assets/service/quick_reply.png" alt="" @click="popFlag = true">
                </template>
                <div class="pop_header">
                  <p>{{ L['常用语快捷回复'] }}</p>
                  <p><i class="el-icon-close" @click="popFlag = false"></i></p>
                </div>
                <div class="pop_list"
                     v-if="quickReplyList&&quickReplyList.list&&quickReplyList.list.length>0">
                  <el-scrollbar v-loading="quickReplyLoading">
                    <div class="pop_item" v-for="(item,index) in quickReplyList.list" :key="index"
                         @click="sendQuick(item.msgContent)">
                      {{ item.msgContent }}
                    </div>
                  </el-scrollbar>
                </div>
                <div class="empty_quick" v-if="!quickReplyList.list.length>0">
                  <img src="@/assets/goods/empty_data.png" alt="">
                  <p>{{ L['暂未设置快捷回复~'] }}</p>
                </div>
              </el-popover>
              <!-- 快捷语end -->

              <!-- 转接start -->
              <img src="@/assets/service/tran_touch.png" alt="" @click="chatTransfer">
              <!-- 转接end -->
            </div>
            <div class="chat_input_area">
                            <pre name="sendBox" contenteditable="true" @keydown="keyDown" class="send-textarea"
                                 @input="preInput"
                                 ref="sendBox"></pre>
            </div>
            <div class="input_button">
              <button @click="send()">{{ L['发送'] }}<i class="iconfont"></i></button>
            </div>
          </div>
          <!-- 聊天输入框end -->
        </div>

        <!-- 客服转接框start -->
        <el-dialog title="客服列表" v-model="dialogVisible" width="20%" :before-close="handleClose" top="25vh">
          <el-scrollbar>
            <div class="transfer_list">
              <div class="transfer_item" v-for="(venItem,venIndex) in vendorList.list" :key="venIndex">
                <img :src="storeInfo.data.storeLogoUrl" alt="">
                <div class="text_con">
                  <p class="service_name">{{ venItem.vendorName }}</p>
                  <p class="service_state">{{ L['在线'] }}</p>
                </div>
                <img src="@/assets/service/contact_on.png" alt=""
                     @click="vendorTrans(venItem.vendorId)">
              </div>
              <div class="empty_trans" v-show="!vendorList.list.length>0">
                <img src="@/assets/goods/empty_data.png" alt="">
                <p>{{ L['暂无在线客服~'] }}</p>
              </div>
            </div>
          </el-scrollbar>
        </el-dialog>
        <!-- 客服转接框end -->
      </section>
      <section v-show="!curChatMemberId">
        <div class="empty_data">
          <img src="@/assets/goods/empty_data.png"/>
          <p>{{ L['请选择访客开启对话～'] }}</p>
        </div>
      </section>
    </div>

    <chatRightList @getObj="getObj" ref="right"/>
  </div>
</template>
<script>
import "element-plus/lib/index.full.js"
import {ref, reactive, getCurrentInstance, onMounted, watch, watchEffect} from 'vue'
import chatLeftList from './chatLeftList';
import chatRightList from './chatRightList';
import {useStore} from "vuex";
import {ElMessage, ElImage} from 'element-plus'
import {emoji, emojiPath} from '@/utils/data.js';
import {storeUrl} from "@/utils/config";
import {pcUrl} from "../../../utils/config";

export default {
  name: 'chatPage',
  components: {
    chatLeftList,
    chatRightList,
    ElImage
  },
  beforeCreate() {
    this.$socket.emit("connect_success", this.connectBaseData);
    //监听连接成功事件
    this.sockets.subscribe('get_room_info', (data) => {
      this.socketInfo.data = data;
    });
    this.sockets.subscribe('get_send_msg', (data) => {
      //如果是会员发送过来的话需要播放声音
      if (data.userType == 1 && (localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
        this.play();
      }
      if (this.curChatMemberId == data.memberId) {
        this.msgList.list.push(data);
      }
    });
    this.sockets.subscribe('get_member_source_url', (data) => {
      this.chatSourceUrl = decodeURIComponent(data.sourceUrl);
    });
    this.sockets.subscribe('get_read_msg', (data) => {
      let allData = data.msgIds.split(',');
      this.msgList.list.map(item => {
        if (allData.indexOf(item.msgId)) {
          item.msgState = 1;
        }
      });
    });
    this.sockets.subscribe('get_member_read_all', () => {
      this.msgList.list.map(item => {
        item.msgState = 1;
      });
    });

    this.sockets.subscribe('recall_msg', (data) => {
      let msgId = data.msgId
      this.msgList.list.forEach(item => {
        if (item.msgId == msgId) {
          item.isRecall = 1 //标记消息，显示消息已撤回词条
        }
      });
    });

  },
  setup() {
    const connectBaseData = {storeId: localStorage.storeId, userId: localStorage.vendorId, role: 2};
    // const connectBaseData = { storeId: 4, userId: 4, role: 2 };
    const {proxy} = getCurrentInstance();
    const store = useStore();
    const quickReplyList = reactive({
      list: []
    })
    const L = proxy.$getCurLanguage()

    const storeInfo = reactive({data: store.state.storeInfo});
    const msgVoiceSetting = ref(store.state.msgVoiceSetting);
    const textarea = ref('');
    const chatSourceUrl = ref('');//当前会员的页面来源
    const currentUserChannel = ref('--');//当前会员的页面来源
    const curChatMemberId = ref('');//当前聊天窗口会员Id
    const curChatMemberInfo = reactive({data: {}});//当前聊天会员的信息
    const socketInfo = reactive({data: {}});//socket连接成功返回的房间信息
    const msgList = reactive({list: []});//聊天列表
    const dialogVisible = ref(false);
    const popFlag = ref(false)
    const hasMore = ref(true);
    const minMsgId = ref('');//当前消息聊天记录列表里的最小消息id
    const vendorList = reactive({
      list: []
    })
    const scrollTop = ref(false)
    const flag = ref('')
    const clientHeight = ref(0)
    const emojis = reactive(emoji)
    const detail = ref(false)
    const detailsForm = reactive({data: {}});
    const srcList = reactive({data: []});
    const quickReplyLoading = ref(true)
    //发送消息
    const send = () => {
      let content = textarea.value; //没有内容的话直接结束
      if (!content.trim() && !content) {
        return false;
      }
      let msgData = {};
      msgData.memberId = curChatMemberId.value; // socketInfo.data.memberId;
      msgData.vendorId = socketInfo.data.vendorId;
      msgData.msgType = '1';
      msgData.msg = {
        content: content
      };
      proxy.$socket.emit("send_msg", {...msgData, ...connectBaseData});
      textarea.value = '';//清空输入框的内容
      proxy.$refs.sendBox.innerHTML = ''
      scrollTop.value = false

      scrolltoNewMsg()
    }
    const recallorder = (e, item) => {
      //阻止事件冒泡
      e.preventDefault()
      let itemDate = new Date(item.addTime)
      let now = new Date()
      //消息间隔分钟数
      let interval = (now - itemDate) / (1000 * 60)
      if (interval > 2) {
        ElMessage({
          message: '超过2分钟，消息无法撤回',
          type: 'warning',
          center: true
        });
      } else {
        proxy.$socket.emit("recall_msg", {...item, ...connectBaseData});
      }
    }
    const recall = (item) => {
      //关闭撤回消息气泡
      proxy.$refs['reca-' + item.msgId].hide()
      //超过两分钟则无法撤回
      let itemDate = new Date(item.addTime)
      let now = new Date()
      //消息间隔分钟数
      let interval = (now - itemDate) / (1000 * 60)
      if (interval > 2) {
        ElMessage({
          message: '超过2分钟，消息无法撤回',
          type: 'warning',
          center: true
        });
      } else {
        proxy.$socket.emit("recall_msg", {...item, ...connectBaseData});
      }

    }

    const preventimg = (e) => {
      e.stopPropagation()
    }


    const toDetails = (id) => {
      let params = {
        productId: id
      }
      detail.value = true
      proxy.$get('v3/goods/seller/goods/helpDeskGetGoods', params).then(res => {
        if (res.state == 200) {
          detailsForm.data = res.data
          srcList.data[0] = res.data.mainImg
        }
      })
    }

    const getGoodsHref = (val) => {
      return storeUrl + 'order/order_lists_to_detail?orderSn=' + val
    }

    const getGoodsDetails = (val) => {
      // return pcUrl + 'goods/detail?productId='+ val
      return pcUrl + 'standard/product/detail?productId=' + val
    }


    const close_dialog = () => {
      detail.value = false // 关闭表单
    }

    //打开客服转接
    const chatTransfer = () => {
      dialogVisible.value = true
      proxy.$socket.emit('pre_switch_vendor', {...connectBaseData}, (data) => {
        vendorList.list = data
      })
    }

    // 点击转接客服
    const vendorTrans = (vendorId) => {
      proxy.$socket.emit('switch_vendor', {
        switchVendorId: vendorId,
        memberId: curChatMemberId.value, ...connectBaseData
      }, (res) => {
        if (res.state == 200) {
          //删除将该会员从最近联系人列表中删除
          proxy.$refs.left.closeChatMember(curChatMemberId.value)
          ElMessage({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
          dialogVisible.value = false;
        }
      })
    }

    //获取现在的会员的信息
    const curChatMemInfo = (data) => {
      if (data.memberId != undefined && data.memberId) {
        curChatMemberInfo.data = data;
      } else {
        curChatMemberId.value = 0;
      }
    }

    //切换会员需要重新获取聊天列表以及用户的信息
    const switchMember = (data) => {
      let {memberId, memberName} = data;
      curChatMemberInfo.data.memberName = memberName;
      curChatMemberId.value = memberId;
      scrollTop.value = false
      hasMore.value = true
      flag.value = memberId.toString()
      minMsgId.value = '';
      msgList.list = [];

      getChatLog();
      currentUserChannel.value = '--'

      //请求当前用户渠道后刷新聊天右侧信息栏
      proxy.$get('v3/member/seller/member/getMemberInfoById', {memberId: curChatMemberId.value}).then(res => {
        if (res.state == 200) {
          console.log('memberinfo', res.data)
          currentUserChannel.value = res.data.memberLastLoginChannelName!=null&&res.data.memberLastLoginChannelName.length>0?  res.data.memberLastLoginChannelName:res.data.memberChannelName;
          let channelCode = res.data.memberChannelCode;
          proxy.$refs.right.getSwitch(curChatMemberId.value, channelCode)
        }
      })
    }

    //监听聊天重连,刷新聊天历史记录
    const reconnect = (data) => {
      let {memberId, memberName} = data;
      curChatMemberInfo.data.memberName = memberName;
      curChatMemberId.value = memberId;
      scrollTop.value = false
      hasMore.value = true
      flag.value = memberId.toString()
      minMsgId.value = '';
      msgList.list = [];
      getChatLog();
    }

    //获取订单，足迹，推荐商品，并发送
    const getObj = (data, type) => {
      let msgData = {};
      msgData.memberId = curChatMemberId.value;
      msgData.vendorId = socketInfo.data.vendorId;
      if (type == 'foot') {
        msgData.msgType = 3;
        msgData.msg = {
          productId: data.productId,
          goodsImage: data.goodsImage,
          goodsName: data.goodsName,
          goodsPrice: data.productPrice,
        };
      } else if (type == 'recom') {
        msgData.msgType = 3;
        msgData.msg = {
          productId: data.productId,
          goodsImage: data.mainImage,
          goodsName: data.goodsName,
          goodsPrice: data.goodsPrice,
        };
      } else if (type === 'order') {
        msgData.msgType = 4;
        msgData.msg = {
          orderSn: data.orderSn,
          createTime: data.createTime,
          orderStateValue: data.orderStateValue,
          productId: data.goodsItem.productId,
          goodsImage: data.goodsItem.productImage,
          goodsName: data.goodsItem.goodsName,
          goodsPrice: data.goodsItem.productShowPrice,
        };
      }
      proxy.$socket.emit("send_msg", {...msgData, ...connectBaseData});
      scrollTop.value = false
      scrolltoNewMsg()
    }

    //获取快捷回复列表
    const getQuickReplyActive = () => {
      let params = {}
      params.pageSize = 100;
      params.isShow = 1;
      params.current = 1;
      proxy.$get('v3/helpdesk/seller/quick/list', params).then(res => {
        if (res.state == 200) {
          quickReplyList.list = res.data.list
          quickReplyLoading.value = false
        }
      })
    }

    //获取聊天记录
    const getChatLog = () => {
      console.log('拉取聊天记录');
      let params = {
        memberId: curChatMemberId.value,
      }
      if (minMsgId.value) {
        params.msgId = minMsgId.value;
      }
      proxy.$post('v3/helpdesk/seller/chat/msgLog', params).then(res => {
        if (res.state == 200) {
          if (minMsgId.value) {
            msgList.list = Array.from(res.data).concat(msgList.list);
          } else {
            msgList.list = res.data
          }
          if (res.data.length > 0) {
            changeMsgState(res.data);
          }
          if (msgList.list.length > 0) {
            minMsgId.value = msgList.list[0].msgId;
          }
          hasMore.value = res.data.length < 10 ? false : true;
        }
      })
    }

    const transEmoji = (src) => {
      const imgSrc = (emojiPath) + "" + (src);
      const imgTag = document.createElement("img");
      imgTag.src = imgSrc;
      proxy.$refs.sendBox.appendChild(imgTag);
      textarea.value = proxy.$refs.sendBox.innerHTML
    }


    const preInput = (e) => {
      textarea.value = e.target.innerHTML
    }

    //  回车发送消息,Ctrl+enter换行
    const keyDown = (e) => {
      if (e.code == 'Enter' && !e.ctrlKey) {
        send()
        e.preventDefault(); // 阻止默认换行操作
        return false;
      } else if (e.code == 'Enter' && e.ctrlKey) {
        proxy.$refs.sendBox.innerHTML += "<br>"; //换行
      }
    }

    //修改当前消息列表的未读消息为已读
    const changeMsgState = (data) => {
      let tmpMsgIdArray = [];
      data.map(item => {
        if (item.userType == 1 && item.msgState == 2) { //设置会员消息已读
          tmpMsgIdArray.push(item.msgId);
        }
        if (item.userType == 3 && item.msgState == 2) { //设置系统转接消息已读
          tmpMsgIdArray.push(item.msgId);
        }
      });
      if (tmpMsgIdArray.length > 0) {
        proxy.$socket.emit("read_msg", {
          msgIds: tmpMsgIdArray.join(','),
          memberId: curChatMemberId.value,
          ...connectBaseData
        });
      }
    }


    //切换店铺的滑动操作，而且触顶加载则保持滚动条在触顶时的位置，不大跳
    watch(() => flag.value, (nv, ov) => {
      let el = ''
      let client = ''
      if (nv != ov) {
        el = document.getElementById('chat').childNodes[0]
        client = document.getElementById('chat')
        scrollTop.value = false
        new MutationObserver(() => {
          if (scrollTop.value) {
            el.scrollTop = client.clientHeight + 400
            return
          }
          scrollToBottom(el);
        }).observe(el, {childList: true, subtree: true});

      }
    })

    watchEffect(() => {
    })


    //滑动至底部方法
    const scrollToBottom = el => {
      el.scrollTop = el.scrollHeight;
      el.scrollTo(0, el.scrollHeight)
    };

    //MutationObserver监听dom变化，当聊天数据最终渲染完成时，将其滑动至底部(优化方法)
    const scroll = () => {
      let el = proxy.$refs.scroll.wrap
      let timeout = ''
      el.addEventListener('scroll', () => {
        if (timeout) clearTimeout(timeout);
        timeout = window.setTimeout(function () {
          if (el.scrollTop == 0 && hasMore.value) {

            scrollTop.value = true
            getChatLog()
          }
        }, 500);
      }, true);
      (new MutationObserver(() => {
        if (scrollTop.value) {
          el.scrollTop = el.clientHeight + 400
          return
        }
        scrollToBottom(el);
      })).observe(el, {childList: true, subtree: true});
    }

    //发送快捷回复
    const sendQuick = (msg) => {
      let msgData = {};
      msgData.memberId = curChatMemberId.value;
      msgData.vendorId = socketInfo.data.vendorId;
      msgData.msgType = '1';
      msgData.msg = {
        content: msg
      };

      popFlag.value = false
      proxy.$socket.emit("send_msg", {...msgData, ...connectBaseData});
      scrollTop.value = false
      scrolltoNewMsg()
    }

    //发送消息时并，滚到最底部
    const scrolltoNewMsg = () => {
      var div = proxy.$refs.scroll.wrap
      proxy.$nextTick(() => {
        div.scrollTo(0, div.scrollHeight + 10000)
      })

    }


    // 消息提示音
    const play = () => {
      let audioElement = document.createElement('audio');
      let voice = require('@/assets/media/msg.mp3')
      audioElement.setAttribute('src', voice);
      audioElement.setAttribute('autoplay', 'autoplay');
    }

    // 发送图片
    const getImage = (e) => {
      if (e.target.files[0]) {
        proxy.$post('/v3/oss/common/upload', {
          source: 'goods',
          file: e.target.files[0]
        }, 'form').then(res => {
          if (res.state == 200) {
            proxy.$refs.imageFile.value = ""
            let msgData = {}
            msgData.memberId = curChatMemberId.value;
            msgData.vendorId = socketInfo.data.vendorId;
            msgData.msgType = '2';
            msgData.msg = {
              pic: res.data.url,
              width: res.data.width,
              height: res.data.height
            };
            proxy.$socket.emit("send_msg", {...msgData, ...connectBaseData});
            proxy.$nextTick(() => {
              setTimeout(() => {
                scrollTop.value = false
                scrolltoNewMsg()
              }, 10)

            })

          }

        })
      }
    }


    onMounted(() => {
      clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
      scroll()
      getQuickReplyActive()
    })

    return {
      quickReplyLoading,
      getGoodsDetails,
      getGoodsHref,
      srcList,
      close_dialog,
      detail,
      detailsForm,
      toDetails,
      keyDown,
      textarea,
      msgList,
      socketInfo,
      send,
      recall,
      recallorder,
      preventimg,
      chatTransfer,
      dialogVisible,
      getObj,
      getQuickReplyActive,
      quickReplyList,
      sendQuick,
      popFlag,
      getChatLog,
      chatSourceUrl,
      currentUserChannel,
      curChatMemInfo,
      curChatMemberInfo,
      switchMember,
      reconnect,
      minMsgId,
      changeMsgState,
      curChatMemberId,
      vendorList,
      vendorTrans,
      play,
      msgVoiceSetting,
      getImage,
      connectBaseData,
      storeInfo,
      clientHeight,
      emojis,
      preInput,
      transEmoji,
      emojiPath,
      L
    }
  }
}
</script>
<style lang="scss">


.pop_header {
  height: 38px;
  background: #F3F3F4;
  display: flex;
  justify-content: space-between;

  p {
    margin-left: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 38px;
  }

  i {
    font-size: 16px;
    margin-right: 10px;
  }
}

.emoji_s {
  height: 200px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  .emoji_item {
    padding: 0 5px;
  }
}

.send-textarea {
  box-sizing: border-box;
  width: 100%;
  height: 94px;
  padding: 0px 19px 0px 19px;
  background-color: #fff;
  color: #252525;
  font-size: 13px;
  font-family: inherit;
  word-break: break-all;
  white-space: normal;
  overflow-y: auto;
  outline: none;
}

.pop_list {
  margin-top: 5px;
  height: 300px;

  .pop_item {
    margin-left: 10px;
    padding: 12px 0px;
    border-bottom: 1px solid #e7e7e7;
    width: 97%;

    &:hover {
      background: #F8F8F8;
    }
  }
}

.goods_info_bottom_detial {
  position: relative;

  .goods_info_order_recall {
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    height: 20px;
    background: #EEEEEE;
    border-radius: 10px;
    bottom: 0;
    right: 70px;
    text-align: center;
    line-height: 20px;
  }

  .goods_info_bottom_ok {
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    height: 20px;
    background: #EEEEEE;
    border-radius: 10px;
    bottom: 0;
    right: 10px;
    text-align: center;
    line-height: 20px;
    color: #666666 !important;
  }
}

#el-textarea {
  border: none;

  textarea {
    outline: none;
    border: none;
  }
}


.recall_box {
  display: flex;
  justify-content: center;
  align-items: center;
}


.sld_chat_page {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;

  .chat_zone {
    width: 1010px;
    height: 100%;

    .empty_data {
      padding-top: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 80px;
      }

      p {
        margin-top: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bbb;
      }
    }

    .top {
      height: 50px;
      padding: 15px 0px;
      display: flex;
      align-items: center;

      .top_title {
        height: 20px;
        line-height: 20px;
        border-left: 4px solid #0563FF;
        padding-left: 10px;
        margin-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .top_channel {
        margin-left: 7px;
        margin-right: 7px;
        color: white;
        background: #169bd5;
        border-radius: 5px;
        padding: 5px;
        line-height: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }

      .top_chat_source_url {
        line-height: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .chat_wrap {
      width: 100%;
      border-radius: 6px;
      /* border-top-right-radius: 6px; */
      border: 1px solid #EEEEEE;

      .chat_window {
        height: 580px;
        background-color: #F5F5F5;
        padding-top: 12px;
        padding-left: 15px;
        padding-right: 13px;

        .time_line {
          margin: 12px auto;
          line-height: 22px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          width: 149px;
          height: 22px;
          background: #EEEEEE;
          border-radius: 11px;
        }

        #text_center {
          width: 589px;
          height: 104px;
          background: #FFFFFF;
          border-radius: 6px;

          .goods_model {
            display: flex;
            justify-content: center;
            align-content: center;
            padding-top: 10px;
            padding-left: 10px;
          }
        }

        .chat_ladder {
          display: flex;
          align-items: flex-end;
          margin-top: 20px;
          margin-bottom: 20px;
          align-items: flex-start;
          box-sizing: border-box;

          & > img {
            width: 38px;
            height: 38px;
            border-radius: 50%;

            &.user_avatar {
              margin-left: 10px;
            }
          }

          .content_right {
            margin-left: 10px;


            p {

              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              margin-bottom: 5px;
            }

            .content_text {
              display: inline-block;
              padding: 11px;
              position: relative;
              max-width: 589px;
              background: #ffffff;
              border-radius: 6px;

            }

            .order_type {

              .goods_info {
                min-width: 300px;

                .goods_info_title {

                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                }
              }

              .order_title {
                display: flex;
                height: 20px;
                justify-content: space-between;
                align-items: flex-start;
                border-bottom: 1px solid #F2F2F2;
                margin-bottom: 8px;

                .order_sn {
                  font-size: 12px;
                  color: #999999;
                  font-family: Microsoft YaHei;

                  i {
                    font-style: normal;
                    color: #666666;
                  }
                }

                .order_time {
                  color: #999999;
                }
              }
            }

            .goods_type {
              min-width: 300px;
            }

            .image_type {

              min-height: 100px;

              img {
                max-width: 200px;
              }

              .image-slot {
                width: 200px;
                height: 200px;
              }
            }

            .text_type {
              max-width: 360px;
              word-break: break-all;
            }
          }

        }


        .user {
          justify-content: flex-start;

          .content_text {
            background: #FFFFFF;

            border-radius: 6px;
          }
        }

        .merchant {
          padding-right: 20px;
          justify-content: flex-end;

          p {
            text-align: right;
          }

          .content_text {
            .tiny_triangle {
              position: absolute;
              right: -9px;
              width: 0;
              height: 0;
              border-right: 14px solid transparent;
              border-bottom: 13px solid #fff;
            }

            background: #fff;
            border-radius: 6px;
          }

          .msg_read {
            align-self: flex-end;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0E6FD7;
          }

          .msg_not_read {
            align-self: flex-end;
            height: 100%;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0E6FD7;
          }
        }
      }

      .chat_input {
        padding-bottom: 10px;

        .chat_tool {
          padding: 6px;

          img {
            margin-right: 10px;
          }
        }

        .chat_input_area {
          padding: 6px;
          margin-top: 6px;

          textarea {
            max-height: 94px;

          }
        }

        button {
          width: 80px;
          height: 30px;
          background: #0E6FD7;
          border-radius: 6px;
          border: none;
          color: #fff;
        }

        .input_button {
          display: flex;
          justify-content: flex-end;
          padding-right: 23px;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }


      }
    }
  }
}

.goods_model {
  display: flex;

  & > img {
    width: 84px;
    height: 84px;
    background: #707070;
    border-radius: 6px;
  }

  .goods_info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;

    .goods_info_title {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-right: 10px;
    }

    .goods_info_bottom {
      display: flex;
      justify-content: flex-start;

      span:first-child {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e2231a;
      }

      span:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e2231a;
      }
    }
  }
}

.transfer_list {
  padding: 11px 0px 11px 11px;
  height: 317px;

  .transfer_item {
    display: flex;
    border-bottom: 1px solid #F2F2F2;
    padding: 12px 0px;
    position: relative;
    height: 70px;
    width: 98%;

    img {
      width: 40px;
      height: 40px;
    }

    img:nth-of-type(2) {
      position: absolute;
      right: 10px;
      top: 22px;
      width: 23px;
      height: 22px;
    }

    .text_con {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .service_name {

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

      }

      .service_state {

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0E6FD7;
        margin-bottom: 3px;
      }
    }

  }
}

.el-dialog__header {

  background: #F3F3F4;
}

.el-dialog__title {

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.empty_quick {
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 87px;
    height: 55px;
  }

  p {
    margin-top: 11px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}

.empty_trans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;

  p {
    margin-top: 11px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}

#image {
  display: none;
}

.forms {
  flex: 1;
  margin-left: 100px;
}

.labels {
  display: inline-block;
  width: 100px;
  margin-top: 20px;
  text-align: right;
}

.red {
  color: red;
  font-weight: bold;
}

.details_btn {
  cursor: pointer;
  border: 1px solid red;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: red !important;
}
</style>
